// 其他相关规划
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'MC',
                label: '名称'
            }, {
                prop: 'LX',
                label: '类型'
            }, {
                prop: 'BZHGBZT',
                label: '编制和公布状态',
                minWidth: 135,
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '遗产要素类型',
            clearable: true,
            placeholder: "请选择遗产要素类型",
            list: [{
                ID: '0',
                NAME: '其他文物保护规划(遗产要素以外的，其他单个、多个文物保护、利用规划)'
            }, {
                ID: '1',
                NAME: '遗址公园规划'
            }, {
                ID: '2',
                NAME: '历史文化名城、名镇、名村保护规划'
            }, {
                ID: '3',
                NAME: '风景名胜区规划'
            }, {
                ID: '4',
                NAME: '旅游规划'
            }, {
                ID: '5',
                NAME: '城市规划'
            }, {
                ID: '6',
                NAME: '土地利用规划'
            }, {
                ID: '7',
                NAME: '交通规划'
            }, {
                ID: '8',
                NAME: '水利规划'
            }, {
                ID: '9',
                NAME: '环保规划'
            }, {
                ID: '10',
                NAME: '林业规划'
            }, {
                ID: '11',
                NAME: '其他'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LX',
            operateType: 'search',
            isShow: true
        }, {
            label: '编制和公布状态',
            clearable: true,
            placeholder: "请选择编制和公布状态",
            list: [{
                ID: '0',
                NAME: '在编'
            }, {
                ID: '3',
                NAME: '通过审批待公布'
            }, {
                ID: '1',
                NAME: '已公布且现行'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'BZHGBZT',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;